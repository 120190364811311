"use client";
import { useAppDispatch } from "@/redux/hook";
import { setCartDetail, setCartLoading } from "@/redux/features/CartSlice";
import { useEffect, useMemo, useState } from "react";
import { CartJson } from "@/types/Cart.type";
import { useSession } from "next-auth/react";
import {
	getListMyCart,
	getListMyCartPublic,
} from "@/lib/api/shop/cart";
import { getDataError } from "@/lib/config/apiHandler";
import useStoreId from "@/lib/hooks/useStoreId";
import useBrowerId from "@/lib/hooks/useBrowerId";
import { LoadingIcon } from "../icons";
import usePromotions from "@/lib/hooks/usePromotions";
import {
	ConfigDealthom,
	PromotionDiscountType,
	PromotionGroupType,
	PromotionJson,
} from "@/types/Promotion.type";
import useCartV2 from "@/lib/hooks/useCartV2";
import { getSettingV2 } from "@/lib/api/shop/setting";
import { PromotionContants } from "@/common/constants/promotion";
import {
	setSettingDealthom,
} from "@/redux/features/PromotionSlice";

type Props = {
	children: React.ReactNode;
};

const CartProviders = ({ children }: Props) => {
	const dispatch = useAppDispatch();
	const [isErr, setIsErr] = useState(false);
	const { data, status } = useSession();
	const user = data?.user.company
	let isUser = false
	if (user && !user.employee) {
	  isUser = true;
	}
	const { data: browser_id, isLoading, isError, error } = useBrowerId();
	const { data: storeId } = useStoreId();
	const { addPromotionCart } = useCartV2({});

	const { data: promotionList } = usePromotions();
	const [err, setErr] = useState<string>("");
	const [cartJson, setCartJson] = useState<null | CartJson>(null);

	const promotionCart = promotionList?.filter(
		(item) =>
			item.discount_type === PromotionDiscountType.CART &&
			item.status &&
			item.group === PromotionGroupType.invoice
	);
	const promotionCoupon = useMemo(() => {
		return promotionList?.filter((p) => p.group === PromotionGroupType.coupon);
	}, [promotionList]);

	const getCartUser = async (store_id: number) => {
		try {
			const myCartCollection = (
				await getListMyCart({ sort_type: "DESC", limit: 1 })
			).data;
			let res: CartJson | null = null;
			if (myCartCollection.items.length > 0) {
				res = myCartCollection.items[0];
			} else {
				// try {
				// 	const { data: cartUser } = await createCartUser({
				// 		store_id: store_id,
				// 	});
				// 	res = cartUser;
				// } catch (error) {}
			}
			return res;
		} catch (error) {
			throw error;
		}
	};
	const getCartPublic = async (store_id: number, browser_id: string) => {
		try {
			let res: CartJson | null = null;
			const listMyCartPublic = (
				await getListMyCartPublic(browser_id, { sort_type: "DESC", limit: 1 })
			).data;
			if (listMyCartPublic.items.length > 0) {
				res = listMyCartPublic.items[0];
			} else {
				// const resCreate = await createCartPublic({
				// 	store_id: store_id,
				// 	browser_id: browser_id,
				// });
				// res = resCreate.data;
			}
			return res;
		} catch (error) {
			throw error;
		}
	};

	async function init(store_id: number, browser_id: string) {
		try {
			dispatch(setCartLoading(true));
			let cart: CartJson | null = null;
			if (isUser) {
				cart = await getCartUser(store_id);
			} else {
				cart = await getCartPublic(store_id, browser_id);
			}
			if (cart) {
				setCartJson(cart);
				dispatch(setCartDetail(cart));
			}
		} catch (error) {
			const dataError = getDataError(error);
			setErr(JSON.stringify(dataError));
			setIsErr(true);
		} finally {
			dispatch(setCartLoading(false));
		}
	}

	async function initPromotion() {
		if (promotionCart && promotionCart.length === 1) {
			const IsPromotionExited = cartJson?.promotions.some(
				(p) => promotionCart[0].id === p.sale_promotion_id
			);

			!IsPromotionExited && (await addPromotionCart(promotionCart));
		}
	}

	async function getConfigDealthom(promotions: PromotionJson[]) {
		try {
			if (promotions.length > 0) {
				const settingDealthom = await getSettingV2(
					PromotionContants.setting_dealthom
				);
				const dataDealthom = settingDealthom.data.value as ConfigDealthom[];
				dispatch(setSettingDealthom(dataDealthom));
			}
		} catch (error) {
			const dataError = getDataError(error);
			setErr(JSON.stringify(dataError));
			setIsErr(true);
		}
	}

	useEffect(() => {
		if (status === "loading") {
			return;
		}
		if (storeId && browser_id) {
			init(storeId, browser_id);
		}
	}, [user?.id, status, storeId, browser_id,isUser]);

	useEffect(() => {
		if (promotionCart && cartJson?.id) {
			initPromotion();
		}
	}, [cartJson]);
	useEffect(() => {
		if (promotionList) {
			getConfigDealthom(promotionList);
		}
	}, [promotionCoupon]);

	// if (isErr || isError) {
	// 	return (
	// 		<ErrorCustom
	// 			from="cart"
	// 			error={{
	// 				...error,
	// 				name: "Cart provider",
	// 				message: err
	// 					? err
	// 					: error?.message
	// 					? error?.message
	// 					: "Cartprovider error",
	// 			}}
	// 			reset={() => {
	// 				location.reload();
	// 			}}
	// 		/>
	// 	);
	// }
	return <main>{children}</main>;
};

export default CartProviders;
