import { PromotionGroupType} from './../../types/Promotion.type';
export const PromotionContants = {
	setting_dealthom: "setting_show_web_code_promotion",
};


function getTypeList()  {
	return [
		{
			value: PromotionGroupType.coupon,
			label: "coupon",
		},
		{
			value: PromotionGroupType.seasonal,
			label: "coupon",
		},
	];
}

function  getType(value: string) {

	return  getTypeList().find((item) => item.value === value);
}




// function  getGroupList(): { value: string; label: string }[] {
// 	return [
// 		{
// 			value: Promotion.GROUP_COUPON,
// 			label: i18n.t("promotion:GROUP_COUPON"),
// 		},
// 		{
// 			value: Promotion.GROUP_SEASONAL,
// 			label: i18n.t("promotion:GROUP_SEASONAL"),
// 		},
// 	];
// }